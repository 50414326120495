export const PACKAGE_ID = 'CLStaticAssets';

export const ClientMessageType = {
    GET_PORT: 'GET_PORT',
    KEEP_ALIVE: 'KEEP_ALIVE',
    SW_ERROR: 'SW_ERROR',
    SW_REDIRECT: 'SW_REDIRECT',
};

export const ERROR_PAGE = 'error-page.html';
export const INJECTED_SCRIPTS = [
    'context-menu.js',
    'redirect-observer.js',
    'UIInjector.js',
    'HotkeyProviderInjector.js',
    'keep-alive.js',
    'satellite-client-module.js',
];
// All static files served from S3 on the /* path
// e.g. /index.html
const STATIC_FILES: string[] = [
    ...INJECTED_SCRIPTS,
    'index.js',
    'index.html',
    'definition.json',
    'resources/*',
    ERROR_PAGE,
    'error-page.js',
];
const STATIC_FILES_REGEX: string = STATIC_FILES.map(file => file.replace('.', '\.')).join('|');

// list of files to pre cache
const cachedScripts: string[] = INJECTED_SCRIPTS.map(script => `./${script}`);
const cachedFiles: string[] = ['./index.js', './index.html'];
export const PRE_CACHE_LIST = cachedScripts.concat(cachedFiles);

//Regex for a CL URL should not be transformed by the service worker.
//These URLs exist and can be got without satellite services.
//Includes the  /, /regionalApi, /api, /index.html, /index.js, /definition.json paths and allowed scripts.
export const CL_FETCH_REGEX = new RegExp(`(.*compatibility\.cs\.amazon\.dev|.*compatibility\.cs\.a2z\.org\.cn)\/(api|regionalApi|${STATIC_FILES_REGEX})($|\/|\\?|\\.).*`, 'i');

//This is a special bypass list for requests that are special exceptions to our fetch logic
//Each bypass should include specific motivation in the comments so we understand why we need to normally fetch the request
export const BYPASS_FETCH_REGEX: RegExp = new RegExp(''
    + /(chrome-extension:\/\/)/.source //any url that contains 'chrome-extension://' to allow 'injected-script/override-ajax-apis.js' https://tiny.amazon.com/1ez2w8i7y/codeamazpackCSOSblob82afsrc
);

/**
 * Regex for CL hostnames.
 */
export const CL_HOSTNAME_REGEX = /(.*compatibility\.cs\.amazon\.dev|.*compatibility\.cs\.a2z\.org\.cn)\/?/i;

/* istanbul ignore next */
export function buildErrorHtml(errorCode: number, errorMessage: string) {
    const html = [];
    html.push(
        `<p><b>Error from Service Worker: ${errorCode}</b></p>`,
        `<p>${errorMessage}</p>`
    );
    return html.join('');
}

/**
 * any href that matches these regexes will be denied.
 */
export const FAST_FAIL_REGEX: RegExp = new RegExp(''
    + /(\d\/batch\/\d)/.source // a kind of CSC CSM
    + '|'
    + /(.*cscentral.*\/gp\/)/.source // gurupa fetch pages
    + '|'
    + /(.*compatibility\.cs\.[^\/]+\/gp\/.*)/.source // gurupa redirect pages
    + '|'
    + /(\/goa\/uedata)/.source // goa client side metrics endpoint
);

export const RECENTLY_CLOSED_CLIENT_QUEUE_SIZE = 10;

export const NAVIGATION_PROMPTED_REDIRECT = 'NAVIGATION_PROMPTED_REDIRECT';
export const NAVIGATION_HISTORY = 'NAVIGATION_HISTORY';
export const NAVIGATION_HISTORY_INDEX = 'NAVIGATION_HISTORY_INDEX';

export const CL_UNIQUE_ERROR_ID = 'COMPATIBILITY_LAYER_ERROR';

export const METRICS_SOURCE_ID = 'compatibility-layer-service-worker'

// PMET does not accept most special characters in Action Name
// This is used to replace special characters with a "."
export const METRICS_SANITIZATION_REGEX = /[&\/\\#, +()$~%'":*?<>{}]/g;

/**
 * CL page initial height.
 */
export const CL_PAGE_INITIAL_HEIGHT: number = 1000;

export const CL_FRAMEWORK_LOG_SOURCEID: string = 'compatibility-layer-service-worker';
